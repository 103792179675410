import React, {FC, useEffect, useRef} from "react";
import {gsap} from 'gsap';
import {useTranslation} from "next-i18next";
import {motion} from 'framer-motion';
import Link from "next/link";
import {BannerType} from "../../../@types/types-header";
import {i18translateType} from "../../../@types/types-body";
import Image from "next/image";

export const Banner: FC<BannerType> = ({banner, namespace}) => {
    const textRefTitle = useRef(null);
    const textRef = useRef(null);


    useEffect(() => {
        // Apply the animation once the component has mounted
        gsap.fromTo(textRefTitle?.current, {x: -100, opacity: 0}, {x: 0, opacity: 1, duration: 3})
        // Animate each letter from top to bottom
        gsap.fromTo(textRef?.current, {y: -100, opacity: 0}, {y: 0, opacity: 1, duration: 3});
    }, []);
    const {t}: i18translateType = useTranslation(namespace);
    return (<div
        className="relative flex items-center mt-10 md:mt-0 justify-center mb-12 maxSm:max-h-[288px] sm:min-h-[345px] md:min-h-[463px] lg:min-h-[600px] xl:min-h-[728px] 2xl:min-h-[850px] 3xl:min-h-[975px]">
        <div
            className="z-10 h-full w-full absolute"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8))`,
            }}
        />
        <Image
            className="object-cover"
            src={banner.backgroundImage}
            fill={true}
            loading="lazy"
            alt={t("banner")}
        />
        <div className="grid grid-cols-1 lg:grid-cols-3  2xl:grid-cols-4 w-full px-4 py-8 z-10 mt-10 sm:mt-10">
            <div className="grid grid-rows-1 font-bold lg:col-span-2 2xl:col-span-3 overflow-hidden">
                <div ref={textRef} className="py-2">
                    <h1 ref={textRefTitle}
                        className="text-base md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-4xl 3xl:text-5xl text-white pb-8 text-left">
                        {t("banner")}
                    </h1>
                    <span
                        className="text-xs md:text-base lg:text-xl 2xl:text-2xl  text-white text-justify italic">{t("banner_description")}</span>
                </div>
                <motion.div
                    id={"banner-button-motion"}
                    className="pt-6 ove"
                    animate={{x: [500, 0]}}
                    transition={{delay: 1, duration: 2}}
                >
                    <Link
                        href={banner.buttonLink}
                        className="bg-crowlyn-button hover:bg-crowlyn hover:text-white text-white rounded-xl text-base sm:text-xl md:text-base py-2 px-10 text-center"
                    >
                        {t("banner_contact")}
                    </Link>
                </motion.div>
            </div>
        </div>
    </div>);
};
