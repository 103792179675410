import React, { FC } from "react";
import { PreHeader } from "@components/sections/header/pre-header";
import { Banner } from "@components/sections/header/banner";
import { HeaderType } from "../../../@types/types-header";
import { NavMenu } from "@components/sections/header/menu/nav-menu";

export const Header: FC<HeaderType> = ({ banner, namespace }) => {
  return (
    <div className="relative overflow-visible">
      <PreHeader />
      <NavMenu />
      <Banner banner={banner} namespace={namespace} />
    </div>
  );
};
