import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { i18translateType } from "../../@types/types-body";

export const LanguageLink: FC<any> = ({
  locale,
  // @ts-ignore
  route,
  showMenu,
  flagsEnum,
}) => {
  const { t }: i18translateType = useTranslation("common");

  return (
    <li
      className={`text-white md:text-gray-900 font-bold hover:text-crowlyn flex w-full hover:bg-gray-100 `}
      aria-expanded={showMenu}
    >
      <Link
        href={`/${locale}`}
        className="flex py-2 px-4 w-fit hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <span className={"flex p-4 md:p-0 md:py-2 md:px-8 w-full"}>
          <div className="rounded-full h-6 w-6 relative">
            <Image
              alt={t(`language.${locale}`)}
              src={flagsEnum[`${locale}`]}
              fill={false}
              className="object-cover rounded-full h-6 w-6"
            />
          </div>
          <span className="crowlyn-language ml-4 ">
            {t(`language.${locale}`)}
          </span>
        </span>
      </Link>
    </li>
  );
};
