import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { MenuSubLinkType } from "../../../../@types/types-header";
import Link from "next/link";
import { i18translateType } from "../../../../@types/types-body";

export const MenuSubLink: FC<MenuSubLinkType> = ({
  id,
  name,
  showMenu,
  subLinks,
  isSubMenuShown,
  route,
  setIsSubMenuShown,
}) => {
  const { t }: i18translateType = useTranslation("common");
  const [activePage, setActivePage] = useState(false);

  function handleExtend(value: string) {
    if (isSubMenuShown === value) {
      setIsSubMenuShown("");
    } else {
      setIsSubMenuShown(value);
    }
  }
  useEffect(() => {
    if (t(`menu.path.${route}`) === t(`menu.path.${name}`)) {
      setActivePage(true);
    }
    subLinks.map((link) => {
      if (t(`menu.path.${route}`) === t(`menu.path.${link.name}`)) {
        setActivePage(true);
      }
    });
  }, []);
  return (
    <li
      onClick={() => handleExtend(`mega-menu-dropdown-button-${id}`)}
      className="p-4 md:p-0"
    >
      <button
        id={`mega-menu-dropdown-button-${id}`}
        data-dropdown-toggle="mega-menu-dropdown"
        className={`${
          activePage
            ? "text-crowlyn"
            : showMenu
            ? "text-white md:text-gray-900"
            : "text-white "
        } ${
          isSubMenuShown ? "text-crowlyn" : ""
        } "p-4 font-bold flex justify-between items-center w-auto  hover:text-crowlyn-light
        md:p-0`}
      >
        {t(`menu.${name}`)}
        <svg
          aria-hidden="true"
          className="ml-1 w-5 h-5 md:w-4 md:h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div
        id="mega-menu-dropdown"
        className={`${
          isSubMenuShown ? "" : "hidden"
        } flex flex-col w-full md:p-4 
        md:absolute md:w-full md:ml-12 md:-translate-x-1/3 lg:-translate-x-1/2 pb-0 md:text-black"`}
      >
        <ul
          className="flex flex-col w-full divide-y divide-white
          md:grid md:z-10 md:w-auto md:text-sm md:align-middle md:bg-white md:rounded-lg
          md:shadow-md md:grid-cols-3 md:divide-y-0 md:mt-5 md:px-4 md:py-2"
          aria-labelledby="mega-menu-dropdown-button"
        >
          <li
            className={`${
              showMenu ? "md:text-gray-900" : "md:text-black"
            } " text-white align-top font-bold hover:text-crowlyn md:text-black text-left`}
          >
            <Link
              href={t(`menu.path.${name}`)}
              id={`mega-menu-link-${id}`}
              className="p-4 w-full inline-block align-middle"
            >
              {t(`menu.${name}`)}
            </Link>
          </li>
          {subLinks.map((link) => (
            <li
              key={`mega-menu-sub-link-link-${link.id}`}
              className={`${
                showMenu ? "md:text-gray-900" : "md:text-black"
              } " text-white font-bold hover:text-crowlyn
                md:align-top md:text-black text-left`}
            >
              <Link
                href={t(`menu.path.${link.name}`)}
                id={`mega-menu-sub-link-link-${link.id}`}
                className="p-4 w-full inline-block align-middle"
              >
                {t(`menu.${link.name}`)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};
