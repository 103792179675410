import {NavLinks} from "../@types/types-header";

export const Menu: NavLinks = [{
    id: 1, name: "home", subLinks: null,
}, {
    id: 2, name: "mobile-development", subLinks: null,
}, {
    id: 3, name: "consultation", subLinks: null,
}, {
    id: 4, name: "web-development-service", subLinks: null,
}, {
    id: 5, name: "company", subLinks: null,
}, {
    id: 6, name: "blog", subLinks: null,
}, {
    id: 7, name: "contact", subLinks: null,
},];
