import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { i18translateType } from "../../../@types/types-body";

export const PreHeader: FC = () => {
  const { t }: i18translateType = useTranslation("common");
  return (
    <div className="bg-crowlyn-dark text-white py-[11px] text-sm md:text-base">
      <div className="container mx-auto">
        <div className="grid items-center grid-cols-12 gap-x-[30px]">
          <div className="col-span-12 md:col-span-6 text-center md:text-left">
            <p>
              {t("pre_header_contact")}
              <Link className="hover:text-secondary" href="tel:+420735548661" id="header-phone-number">
                +49 1512 3376347
              </Link>
            </p>
          </div>
          <div className="col-span-12 md:col-span-6 text-center md:text-right" id="header-text">
            <p>{t("pre_header_social")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
