import React, {FC} from "react";
import Link from "next/link";
import Logo from "@images/identity/crowlyn.webp";
import Image from "next/image";
import {motion} from 'framer-motion';

export const LogoLink: FC = ({}) => {
    return (<>
        <motion.div
        className="box"
        animate={{
            scale: [1, 3, 3, 1, 1], rotate: [0, 0, 360, 360, 0], borderRadius: ["0%", "0%", "50%", "50%", "0%"]
        }}
        transition={{
            duration: 2, ease: "easeInOut", times: [0, 0.2, 0.5, 0.8, 1]
        }}
    >
        <Link
            href="/"
            className="hidden md:flex text-xl font-bold text-gray-800 md:text-2xl hover:text-blue-400 h-20 w-20">
            <Image src={Logo} alt="Crowlyn" className="h-20 w-20" fill={false}/>
        </Link>
    </motion.div></>
    );
};
