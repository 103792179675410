import { FC } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { MenuLinkType } from "../../../../@types/types-header";
import { i18translateType } from "../../../../@types/types-body";

export const MenuLink: FC<MenuLinkType> = ({ id, name, showMenu, route }) => {
  const { t }: i18translateType = useTranslation("common");
  return (
    <li
      className={`${
        t(`menu.path.${route}`) === t(`menu.path.${name}`)
          ? "text-crowlyn"
          : showMenu
          ? "text-white md:text-gray-900"
          : "text-white"
      } p-4 md:p-0 font-bold hover:text-crowlyn-light `}
    >
      <Link
        href={t(`menu.path.${name}`)}
        id={`mega-menu-link-${id}`}
        className="hover:text-crowlyn-light "
      >
        {t(`menu.${name}`)}
      </Link>
    </li>
  );
};
