import React, { FC } from "react";
import { MenuType } from "../../../../@types/types-header";
import { MenuSubLink } from "@components/sections/header/menu/menu-sub-link";
import { MenuLanguageHeaderDropDown } from "@components/sections/header/menu/menu-language-header-drop-down";
import { MenuLink } from "@components/sections/header/menu/menu-link";

export const NavMenuPanel: FC<MenuType> = ({
  scrolledMenu,
  showMenu,
  setShowMenu,
  menu,
  isSubMenuShown,
  setIsSubMenuShown,
  route,
}) => {
  return (
    <div
      key="navbar-menu"
      id="navbar-menu"
      className="flex flex-col relative divide-y divide-white align-middle
      w-full md:block md:w-auto md:divide-y-0 md:mr-0"
    >
      <div className="flex flex-full justify-end px-4 md:hidden">
        <button
          className="block text-black md:text-white
           text-end hover:text-white focus:outline-none focus:text-gray-400 py-2 md:p-6"
          type="button"
          onClick={() => setShowMenu(!showMenu)}
          aria-label="mobile menu"
        >
          <svg className="w-8 h-8 fill-white" viewBox="0 0 24 24">
            <path
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div>
        <ul
          className={`${
            showMenu ? (scrolledMenu ? "" : "") : "hidden"
          } flex flex-col w-full divide-y md:divide-y-0 
      divide-white h-screen overflow-auto
      md:h-fit md:overflow-hidden md:mt-8 md:flex md:space-y-0 md:flex-row md:space-x-10`}
        >
          {menu.map((link) =>
            link.subLinks ? (
              <MenuSubLink
                key={`mega-menu-sub-link-${link.id}`}
                id={link.id}
                showMenu={scrolledMenu}
                name={link.name}
                subLinks={link.subLinks}
                isSubMenuShown={isSubMenuShown}
                setIsSubMenuShown={setIsSubMenuShown}
                route={route}
              />
            ) : (
              <MenuLink
                key={`mega-menu-link-${link.id}`}
                id={link.id}
                showMenu={scrolledMenu}
                name={link.name}
                route={route}
              />
            )
          )}
          <MenuLanguageHeaderDropDown showMenu={scrolledMenu} route={route} />
        </ul>
      </div>
    </div>
  );
};
