import { FC, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import DeIcon from "@images/flags/de.svg";
import UsIcon from "@images/flags/en.svg";
import Image from "next/image";
import { LanguageLink } from "@components/links/language-link";
import { i18translateType } from "../../../../@types/types-body";

export const MenuLanguageHeaderDropDown: FC<{ showMenu: any; route: any }> = ({
  showMenu,
  route,
}) => {
  const { t }: i18translateType = useTranslation("common");
  const [showLanguage, setShowLanguage] = useState(false);
  const router = useRouter();
  const flagsEnum: any = {
    de: DeIcon,
    en: UsIcon,
  };
  return (
    <div className="p-4 md:p-0 mdToLG:pr-4">
      <button
        id="dropdownDividerLanguageButton"
        data-dropdown-toggle="dropdownDivider"
        className={`${
          showMenu ? "text-white md:text-gray-900 " : "text-white "
        } text-center inline-flex items-center relative`}
        type="button"
        onClick={() => setShowLanguage(!showLanguage)}
      >
        <span className="mr-2 font-bold mdToLG:hidden">
          {t(`language.${router.locale}`)}
        </span>
        <div className="rounded-full h-6 w-6 relative">
          <Image
            alt={t(`language.${router.locale}`)}
            src={flagsEnum[`${router.locale}`]}
            fill={false}
            className="object-cover rounded-full h-6 w-6"
          />
        </div>
      </button>
      <div
        id="dropdownLanguageDivider"
        className={` ${
          showLanguage
            ? "visible opacity-100 right-1"
            : "max-h-0 max-w-0 opacity-0 invisible py-0"
        } md:mt-2 md:absolute transform transition-opacity ease-in-out delay-75 duration-300`}
      >
        <ul
          className="flex flex-col w-full divide-y divide-white
          md:grid md:z-[300] md:w-auto md:text-sm md:align-middle md:bg-white md:rounded-lg
          md:shadow-md md:grid-cols-1 md:divide-y-0 "
          aria-labelledby="mega-menu-dropdown-button"
        >
          <LanguageLink
            locale={"en"}
            route={route}
            showMenu={showMenu}
            flagsEnum={flagsEnum}
          />
        </ul>
      </div>
    </div>
  );
};
// <LanguageLink
//             locale={"de"}
//             route={route}
//             showMenu={showMenu}
//             flagsEnum={flagsEnum}
//           />
